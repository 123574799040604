import { fetchGet,fetchPost,fetchDelete,fetchPut } from "../../router/https";
function getCar(data){ 
    return fetchGet('/createrapp/makershoppingcart/page',data)
}
function addCar(data){ 
    return fetchPost('/createrapp/makershoppingcart',data)
}
function editCar(data){ 
    return fetchPut('/createrapp/makershoppingcart',data)
}
function delCar(data){ 
    return fetchDelete('/createrapp/makershoppingcart/'+data)
}
function delCars(data){ 
    return fetchPost('/createrapp/makershoppingcart/removeByIds',data)
}

export { 
    //获取购物车的数据
    getCar,

    //购物车商品数量增加
    addCar,
    //编辑购物车数据
    editCar,
    //删除购物车商品
    delCar,
    //删除多件商品
    delCars
}