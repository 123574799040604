<template>
      <div class="shopping-cart"  @touchmove.self="(e)=>{e.stopPropagation()}" >
        
        <Head :type="0" :backType="1" >

                  <!-- page名 -->
                  <template #title>


                            <div class="name" style="height: 100%" @click="rollTop()"> 购物车</div>
                    
                  </template>
                  
                
                  <template #right>

                            <!--  操作商品 -->
                            <div class="name" style="height: 100%;padding-right: 0.56rem;"  align="right" v-show="manage"> <span  @click="manage=false">完成</span>  </div>

                            <div class="name" style="height: 100%; padding-right: 0.56rem"  align="right"  v-show="!manage"> <span @click="manage=true">管理</span> </div>
                    
                  </template>

        </Head>

        <div class="main">
                      <!-- 购物车无商品时显示的状体图 -->
                      <img  class="kong"  src="../../../assets/img/kkry.png" alt="" v-show="!list.length&&showImg" />
                      
                      <!-- 上拉加载 -->
                      <van-list  loading-text="加载中..." v-model="loading" :finished="finished"    @load="onLoad"  :immediate-check="false" >
           
                                <div class="warp" v-if="list">

                                            <!-- 店铺列表 -->
                                            <div class="shop" v-for="item,num in list" :key="item.id" v-show="item.goodArr.length">

                                                        <div class="shop-box"  v-if="item.goodArr.length">

                                                                    <!-- 店铺未选中的状态 -->
                                                                    <img  v-if="!item.isCheck"  class="check" src="../../../assets/img/checkout.png" alt="" @click="checkItem(item)" />
                                                                  
                                                                    <!-- 店铺选中的状态 -->
                                                                    <img  v-if="item.isCheck"  class="check"  src="../../../assets/chooseRead.png" alt=""  @click="checkItem(item)" />
                                                                  
                                                                    <!-- 店铺名 -->
                                                                    <span>{{ item.storeBasic.storeName }}</span>
                                                          
                                                        </div>

                                                        <!-- 商品列表 -->
                                                        <van-swipe-cell  v-for="li, index in item.goodArr" :key="li.id+index"  :class="{'null-border': index!=item.goodArr.length-1}" >
                                                                        
                                                                        <div class="goods-box" v-if="li" >
                                                                          
                                                                                          <div class="goods-list">

                                                                                                      <!-- 商品未选中的状态 -->
                                                                                                      <img v-if="!li.isCheck"  class="check" src="../../../assets/img/checkout.png" alt="" @click="goodCheck(item,li)" />
                                                                                                    
                                                                                                      <!-- 商品选中的状态 -->
                                                                                                      <img v-if="li.isCheck"  class="check"  src="../../../assets/chooseRead.png" alt=""  @click="goodCheck(item,li)" />
                                                                                                      
                                                                                                      <!-- 商品图片 -->
                                                                                                      <img  class="goods-img" :src="li.picUrl"  alt="" @click="$jump(7,li.storeProductId)"  />
                                                                                            
                                                                                            
                                                                                            
                                                                                          
                                                                                                      <!-- @click="$router.push({ path:'/makerGoodsDetail',query:{id=li. }})" -->

                                                                                                      <div class="goods-info">

                                                                                                                <!-- 商品名 -->
                                                                                                                <div class="goods-name  van-multi-ellipsis--l2" @click="$jump(7,li.storeProductId)"> {{ li.makerProduct.subject }}</div>
                                                                                                        
                                                                                                        
                                                                                                                <div class="lable-box">

                                                                                                                              <!-- 商品规格列表 -->
                                                                                                                              <div class="lable"  @click="$jump(7,li.storeProductId)" v-for="info,index in li.makerProductSkuAttributes" :key="info.id"  v-show="index<2" >{{info.attributeValue}}</div>
                                                                                                                            
                                                                                                                              <!-- v-if=" li.makerProductSkuAttributes[0].attributeValue!=null" -->


                                                                                                                </div>

                                                                                                                <div class="price">

                                                                                                                  <!-- 单个商品计价 -->
                                                                                                                <span><span class="logo">￥</span>{{li.addPrice}}</span >
                                                                                                        
                                                                                                                <!-- li.makerProduct.buyPrice -->
                                                                                                                
                                                                                                                <!-- 商品数量操作 -->
                                                                                                                <div class="change">

                                                                                                                              <div class="jian" @click="changeNum(item,index,-1 )">-</div>

                                                                                                                              <!-- 商品数量 -->
                                                                                                                              <div class="number">{{ li.quantity }}</div> 

                                                                                                                              <div class="jia"  @click="changeNum(item,index,1 )">+</div>

                                                                                                                </div>
                                                                                                    </div>
                                                                                                      </div>
                                                                                            
                                                                                          </div>
                                                                        

                                                                        </div>

                                                                        <!-- 左滑删除 -->
                                                                        <template #right>

                                                                                      <van-button   square  type="danger" text="删除" style="height: 100%; border-radius: 0 0.13rem 0.13rem 0 ;margin-left:0.05rem;" @click="delOne(item,index,num)" />
                                                                        
                                                                        </template>
                                                          

                                                                        

                                                        </van-swipe-cell>
                                                        
                                            </div>
                                </div>
                                <div class="bottom" v-show="finished&&list.length>0">已经到底了...</div>
                        </van-list>

          
        </div>

        <!--  底部按钮 -->
        <div class="footer">

                        <div class="settlement">

                                        <div class="left"  >

                                                        <!-- 全选 ——>未选中 -->
                                                        <img v-if="!checkAll" class="check" src="../../../assets/img/checkout.png"  @click="checkAll=!checkAll" alt="" />
                                                        
                                                        <!-- 全选 ——>选中 -->
                                                        <img  v-if="checkAll"  class="check" src="../../../assets/chooseRead.png" alt="" @click="checkAll=!checkAll"  />全选

                                        </div>
                                        
                                        <div class="right">  合计：￥{{priceAll}}
                                      
                                                        <div class="btn" v-show="!manage" @click="buy()">结算({{total.length}})</div>

                                                        <div class="btn" v-show="manage" @click="delS()">删除({{total.length}})</div>

                                        </div>

                        </div>
        </div>
      </div>
</template>

<script>
import Head from "../../../components/head/index.vue";
import { getCar,delCar,editCar,delCars} from "../../../api/maker/car";
import smoothscroll from 'smoothscroll-polyfill'// 滚动动画
// ,addCar
export default {
  // name:'keep',
  data() {
    return {


      loading: false,  
      finished: false,


      page: 1,
      size:20,
       

      list: [],
      arr: [],

      arr2: [],
      total:[],

      manage:false,
      showImg:false,
      top:JSON.parse(sessionStorage.getItem('top'))??null
     
     
    };
  },
  activated(){ 

          // 判断是否是从下单页过来的 并是否下单
          if(sessionStorage.getItem('makerStatus')){ 

                // 下单就删除所有已选中的商品
                this.delS()

                sessionStorage.removeItem('makerStatus')

          } 

            // 获取离开页面时浏览的位置
            let  left = sessionStorage.getItem('statusTop')

            document.querySelector('.van-list').scrollTo(0,left)

            sessionStorage.removeItem('stausTop')

            left=null
  },
  created() {

      this.setRem()
     
      let session = this.$route.query.session
      if(session){ 

            this.$store.commit('setSession',session)

      }

      this.showImg=false

      // 获取购物车商品列表
     this.getList()
  },


  methods: {


    onLoad() {

              this.page++

              this.getList()

              return
              
    },

    getList(){ 

               getCar({ current: this.page, size:( this.top ?. page  ??  1 )*this.size }).then((res) => {

                if (res.data.code === 0) {

                               this.arr2 = [...this.arr2, ...res.data.data.resultPage.records];

                                this.loading=false
                                if(res.data.data.resultPage.records.length<this.size){ 

                                    console.log(res.data.data.resultPage.records.length,555)

                                          this.finished=true

                                }

                                // 循环为商品添加isCheck字段  该字段用来判断是否被选中  默认未选中
                               this.arr2.forEach((item, index) => {

                               item.isCheck=false

                              // 获取店铺id
                               this.arr[index] = this.arr2[index].storeBasic.id;
                  });


                  // 店铺id去重
                  this.arr = new Set(this.arr);

                  this.arr = Array.from(this.arr);

                  //根据商品ID获取店铺信息   // 店铺信息以任意一个商品数据作为店铺信息
                  this.arr2.forEach((item) => {

                                this.arr.forEach((li, index) => {

                                              if (item.storeBasic.id === li) {

                                                this.arr[index] = this.deepClone(item);

                                              }

                                });
                  });
                

                  this.arr.forEach(item=>{ 

                                // 店铺下商品选中 总价小计 
                                item.priceAll=0

                                // 店铺下商品数组
                                item.goodArr=[]

                  })

                  this.arr2.forEach((item) => {

                              this.arr.forEach((li, index) => {

                                               item.isCheck = false;

                                              if (item.storeBasic.id - li.storeBasic.id == 0) {
                                                
                                                this.arr[index].goodArr.push( this.deepClone(item));
                                                
                                              }

                              });

                  });

                  
                  this.list = [...this.arr] ;
                  console.log(this.top);
                  if(this.top){ 

                            this.$nextTick(()=>{ 
                                    
                                            document.querySelector('.van-list').scrollTo({top: Number(this.top.scrollTop)})
                                            sessionStorage.removeItem('top')
                                            this.page= this.top.page
                                            this.top=null
                              });

                  }
                   
                }

            this.showImg=true

      });




    },

    // 点击 店铺checkImg
    checkItem(item){ 

            console.log(this.list);

            //  修改店铺isCheck状态
            item.isCheck=! item.isCheck

            // 循环修改下属商品的isCheck状态
            item.goodArr.forEach(li=>{ 

                               li.isCheck=item.isCheck

              })

            // 计算选中商品数量
            this.totalNum()

            // 计算选中商品金额
            this.clcaPrice(item)
    },

    check(arr,item){ 


            item.isCheck = !item.isCheck

            arr.isCheck= arr.every((arr)=>{ 

                    return arr.goodArr.isCheck

            })

            
            this.clcaPrice(arr)

    },


    // 深克隆
   deepClone(target) {

              // 判断如果不是引用类型，直接返回数据即可
          if (target === null) return null;

                      if (typeof target !== 'object') return target;

                                    const cloneTarget = Array.isArray(target) ? [] : {};

                      for (let prop in target) {


                                    if (Object.prototype.hasOwnProperty.call(target,prop)) {

                                                // Object.prototype.hasOwnProperty() 方法会返回一个布尔值，指示对象自身属性中是否具有指定的属性（也就是，是否有指定的键）
                                                  cloneTarget[prop] = this.deepClone(target[prop]);

                                    }
                      }
          
          return cloneTarget;



  
  },
  //点击 单个商品的选择按钮
  goodCheck(item,li){ 
  


          li.isCheck=!li.isCheck

          item.isCheck=item.goodArr.every((good)=>{

                    return (good.isCheck)

          })

          this.clcaPrice(item)
      
   
    },




    // 删除单个商品
    delOne( arr,index,num){ 

    
              delCar(arr.goodArr[index].id).then(res=>{ 

                    if(res.data.code===0){ 

                                  this.$toast.success('删除成功')

                                  this.clcaPrice(arr)

                                  try{ 
                                    
                                          if(arr.goodArr.length===1){ 

                                                    this.list.splice(num,1)
                                          
                                          }else{ 

                                                    arr.goodArr.splice(index,1)

                                          }

                                  }catch(err){

                                    //

                                  }
                    
        
                    }else{ 

                                  this.$toast.fail('删除失败')
          
                    }

                    // 如果店铺下无商品 就删除这个店铺
                    if(this.list.length==0){ 
                      
                      this.list=[]

                    }
      })

    },

    // 计算商品总金额
    clcaPrice(item){
      
              let  sum =0,goodNum=0
              
              item.goodArr.forEach(li=>{ 

                          if(li.isCheck){ 

                                sum+= li.quantity*(li.addPrice *100)

                                // li.makerProduct.salePrice

                                goodNum+=li.quantity


                            }
              })

              this.totalNum()

              item.goodNum=goodNum

              item.priceAll=sum/100
    },


    changeNum(arr,index,number){ 
      console.log(arr);
      console.log(index);
      console.log(number);
      console.log(arr.goodArr[index].quantity);
      console.log(arr.goodArr[index].minOrderQuantity - 0);

            
            if( (arr.goodArr[index].quantity+number) < (arr.goodArr[index].minOrderQuantity - 0)){ 
              this.$toast('数量过低,如不需要请左滑删除')
              return
            }

            // if( [0,arr.goodArr[index].minOrderQuantity - 0].includes(arr.goodArr[index].quantity+number)){ 

            //             this.$toast('数量过低,如不需要请左滑删除')

            //             return

            // }

            // 修改商品数量
            editCar({ 
                      id:arr.goodArr[index].id,

                      quantity:arr.goodArr[index].quantity+number

                    }).then(res=>{ 


                                        if(res.data.code===0){ 

                                                    

                                                      arr.goodArr[index].quantity=  arr.goodArr[index].quantity+number

                                                      this.clcaPrice(arr)
                                      
                                        }
                                  })
    },


    // 计算选中商品数量
    totalNum(){ 

              this.total=[]

              this.list.forEach(item=>{ 

                            item.goodArr.forEach(li=>{ 

                                        if(li.isCheck){ 

                                                this.total.push(li)

                                        }
                            })
              
              })
    },

    // 删除商品
    delS( ){ 

              let arrList =[]
              this.total.forEach(item=>{ 

                        arrList.push(item.id)

              })

              if(arrList.length<1){ 

                        this.$toast('请先选择删除商品')

                        return

              }

              delCars({ids:arrList}).then(res=>{

                        if(res.data.code===0){ 

                                      this.$toast.success("删除成功")

                                      this.total=[]

                                      for( let i =0 ;i < this.list.length;i++){ 

                                                  for(let n = 0 ;n < this.list[i].goodArr.length;n++){ 

                                                            if(this.list[i].goodArr[n].isCheck){ 

                                                            this.list[i].goodArr.splice(n,1)

                                                            --n
                                                            
                                                            }
                                                  }

                                                  if( this.list[i].goodArr.length<1){ 

                                                          this.list.splice(i,1)

                                                          --i

                                                  }

                                      }
                    console.log(this.list)
                        }
              })
    },

    // 下单方法
    buy(){ 
          if(this.total.length>5){ 
            this.$toast('单次下单最多选择5件商品')
            return
          }
              if(!this.list.length||this.priceAll==0){ 

                          this.$toast('请先选择商品')

                          return

              }

              let goodList = this.deepClone(this.list)
        

      
      
              for( let i =0 ;i < goodList.length;i++){ 

                      for(let n = 0 ;n < goodList[i].goodArr.length;n++){ 

                                  if(!goodList[i].goodArr[n].isCheck){ 

                                  goodList[i].goodArr.splice(n,1)

                                  --n
                                  
                                  }
                      }
                      if( goodList[i].goodArr.length==0){ 

                                  goodList.splice(i,1)
                                  
                                  --i
                      }

              }

              sessionStorage.setItem('goodList',JSON.stringify(goodList) )

              this.$router.push('/upOrder')

              sessionStorage.setItem('statusTop',document.querySelector('.van-list').scrollTop)

    },

      rollTop(){ 

              smoothscroll.polyfill()

              document.querySelector('.van-list').scrollTo({  top:0, behavior:'smooth'})
                   
      }
    

  },
  components: {
    Head,
  },
 beforeRouteLeave(to ,from ,next){ 

                sessionStorage.setItem('top',JSON.stringify({ page:this.page,  scrollTop:document.querySelector('.van-list').scrollTop+''}))

                next()
            },

  computed:{ 

          // 判断是否全选商品
          checkAll:{ 

                  get(){ 

                            if(this.list.length<1) {

                              return false
                              
                            }

                            return  this.list.every((item)=>{ 

                                  this.totalNum()

                                return item.isCheck
                                
                              })
                    
                  },

            set(val){ 


                              this.list.forEach(item=>{ 

                                          item.goodArr.forEach(li=>{ 

                                                        li.isCheck=val
                                            
                                          })

                                          item.isCheck=val

                                          this.clcaPrice(item)

                              })
                              
                              this.totalNum()
              }
          },
          
    priceAll: { 


            get(){ 
                    let sum =0

                    this.list.forEach(item=>{ 

                                sum+= item.priceAll*100

                    })

                    return sum/100
                }
    }
  }
};
</script>

<style lang="less" scoped>
.shopping-cart {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: white;
  .check {
    width: 0.64rem;
    height: 0.64rem;
    margin-right: 0.266667rem;
  }
  .main {
    background-color: #f1f1f1;
    box-sizing: border-box;
    flex: 1;
    overflow: hidden;
    padding: 0 0.133333rem;
    .van-list::-webkit-scrollbar{
      display: none;
    }
    /deep/ .van-list {
      height: 100%;
      box-sizing: border-box;
      overflow: auto;
      
      .warp {
        margin: 0.4rem 0;
      }
    }
    .kong {
      width: 4.88rem;
      height: 5.333333rem;
      margin-top: 50%;
      margin-left: 50%;
      transform: translate(-50% ,-50%);
    }

    .shop {
      background-color: white;
      border-radius: 0.133333rem;
      padding: 0.266667rem;
      font-size: 0.373333rem;
      color: #333;
      margin-bottom: 0.266667rem;
      .shop-box {
        display: flex;
      }
    // .null-border{ 
    //   border-bottom: 1px solid #f6f6f6;
    //  }
     .van-swipe-cell__wrapper{ 
       width: calc(100%-1px);
     }
      .goods-box {
       width: 100%;
        padding: 0.266667rem 0;
        box-sizing: border-box;
        font-family: PingFangSC-Regular, PingFang SC;
        .goods-list {
          display: flex;
          box-sizing: border-box;
          border-radius: .266667rem;
          overflow: hidden;
          .check {
            margin-top: 1.066667rem;
            transform: translateY(-50%);
           
          }
          .goods-img {
            width: 2.133333rem;
            height: 2.133333rem;
            border-radius: 0.133333rem;
            margin-right: 0.266667rem;
          }
          .goods-info {
            flex: 1;
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 100%;
            justify-content: flex-start;
            box-sizing: border-box;
            .goods-name {
              flex: 1;
              box-sizing: border-box;
            }
            .lable-box::-webkit-scrollbar{ 
              display: none;
            }
            .lable-box{ 
               display: flex;
              align-items: center;
              font-size: .373333rem;
              overflow-y : auto;
              flex-wrap: nowrap;
              margin: .266667rem 0;
              box-sizing: border-box;
              width: 5.666667rem;
            }
           
            .lable {
              background-color: #F1F1F1;
              margin-right: .133333rem;
              width: auto;
              padding:0 .066667rem;
              height: 0.586667rem;
             
              color: #999;
            }
          }
        }
        
        .price {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-right: .266667rem;
          span {
            color: #a80000;
            font-size: 0.48rem;
            
            .logo {
              font-size: 0.373333rem;
            }
          }
          .change {
            display: flex;
            align-items: center;
            div {
              min-width: 0.8rem;
              height: 0.746667rem;
              background-color: #f6f6f6;
              margin-left: 0.066667rem;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }
      }
      
     
    }
   
  }
  
  .footer {
    display: flex;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    background-color: white;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
     position: relative;
    .settlement {
      padding: 0.266667rem 0.4rem;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 0.373333rem;
      color: #333333;
      // border-bottom: 1px solid #999;
      .left {
        display: flex;
        align-items: center;
      }
      .right {
        display: flex;
        align-items: center;
        .btn {
          width: 2.426667rem;
          height: 0.96rem;
          display: flex;
          align-items: center;
          justify-content: center;
          color: white;
          background-image: linear-gradient(to right, #ff7200, #ff3c00);
          border-radius: 0.48rem;
          margin-left: 0.266667rem;
        }
      }
    }
  }
}
.name{ 
  font-size: 0.453333rem;
      font-family: SFProDisplay-Regular, SFProDisplay;
}
 .wrap{ 
          display: flex;
          align-items: center;
          flex-shrink: 0;
          overflow-x: auto;
          box-sizing: border-box;
          width: 5.333333rem;
          flex-shrink: 0;

        }
          .lable {
            font-size: 0.373333rem;
            color: #999;
            display: flex;
            justify-content: space-between;
            background-color: #f1f1f1;
            margin-right: .133333rem;
            padding: 0 .133333rem;
            white-space: nowrap;
            border-radius: .066667rem;

          }
          .bottom{ 
            text-align: center;
            margin-bottom: 20px;
            font-size: 12px;
            color: #999;
          }
          .van-swipe-cell{ 
            border-radius: .266667rem;
            overflow: hidden;
          }
</style>